import { Controller } from "stimulus"

export default class extends Controller
  changeToggle: () ->
    c = @.element.children
    if c['lang-en'].classList.value == ''
      document.getElementById(c['lang-en'].id).classList.add("uk-hidden")
      document.getElementById(c['lang-es'].id).classList.remove("uk-hidden")
    else
      document.getElementById(c['lang-es'].id).classList.add("uk-hidden")
      document.getElementById(c['lang-en'].id).classList.remove("uk-hidden")
