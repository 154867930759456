import {Controller} from "stimulus"
import AxiosRequest from "../../utils/axios-request"

export default class extends Controller
  @targets = ['name', 'email', 'address', 'code', 'city', 'state']
  sweeConfig =
    showCancelButton: true,
    confirmButtonText: 'ok'
    inputAttributes:
      autocapitalize: 'on'
      autocorrect: 'on'
    customClass:
      confirmButton: 'blue-button'
    allowOutsideClick: false
  connect: ->
    @uid = @.data.get 'uid'
    @url = @data.get 'model_url'
    @search_url = @data.get 'search'
    @states_url = @data.get 'states'
    @models = @data.get 'models'
    @model = @data.get 'name'
    @id_model = @data.get 'id'

  SearchHolder: ->
    request = Swal.fire {
      sweeConfig...
      title: "look up the #{@model}'s name",
      width: '50%'
      cancelButtonText: 'Cancel'
      confirmButtonText: 'Next'
      html: "<input type=\"text\" name=\"holder\" id=\"seach_keywork\" class=\"uk-input\" placeholder=\"Enter name of #{@model}\">"
    }

    request.then ({value}) =>
      if value?
        uid = @uid
        search = document.getElementById('seach_keywork').value
        AxiosRequest("#{@url}", {search}, 'post').then ({data}) =>
          if data.enabled == true
            @SearchResul(@model, data, @search_url, @id_model)
          else
            # si no hay ningun resultado en la busqueda
            states = await $.get("#{@states_url}")
            @Register(states, @model, @id_model, @models)

  SearchResul: (model, data, url, id) ->
    options = undefined

    if model == 'Producer'
      options = data.producers.map((e) => ("<option value=\"#{e.idproducer}\">#{e.name}</option>"))
    else if model == 'Insured'
      options = data.insureds.map((e) => ("<option value=\"#{e.idinsured}\">#{e.name}</option>"))
    else if model == 'Holder'
      options = data.holders.map((e) => ("<option value=\"#{e.idholder}\">#{e.name}</option>"))


    Swal.fire({
      sweeConfig...
      title: "Select an #{model}",
      confirmButtonText: 'Next'
      cancelButtonText: 'Cancel'
      width: '50%'
      html: "<select id='option' class='uk-input uk-select'>"+options+"</select>"
    }).then ({value}) =>
      if value?
        select = document.getElementById('option').value
        $.get("#{url}", {select}).done (data) =>
          @emailTarget.textContent = data.email unless model == 'Producer'
          document.getElementById("#{id}").value = data.id
          @nameTarget.textContent = data.name
          @addressTarget.textContent = data.address
          @cityTarget.textContent = data.city
          @stateTarget.textContent = data.state
          @codeTarget.textContent = data.code

  Register: (states, model, id, models) ->
    options = states['states'].map((e) => ("<option value=\"#{e.idstate}\">#{e.state} - #{e.code}</option>"))

    swal = @form(model, options)

    swal.then ({value}) =>
      if value?
        name = document.getElementById('name_form').value
        address = document.getElementById('address_form').value
        zip_code = document.getElementById('zip_code_for').value
        state = document.getElementById('state_form').value
        cities = document.getElementById('cities_form').value
        email = document.getElementById('email_form').value if model == 'Insured' || model == 'Holder'
        authenticity_token = $('[name="csrf-token"]')[0].content
        if model == 'Producer'
          producer = @fill_producer(name, address, zip_code, state, cities)
        else if model == 'Insured'
          insured = @fill_insured(name, address, zip_code, state, cities, email)
        else if model == 'Holder'
          holder = @fill_holder(name, address, zip_code, state, cities, email)
        @create(models, model, id, producer, insured, holder, authenticity_token) if producer || insured || holder

  form: (model, options) ->
    if model == 'Producer'
      Swal.fire({
        sweeConfig...
        title: "Register #{model}",
        cancelButtonText: 'Cancel'
        confirmButtonText: 'Register'
        width: '50%'
        html: '
          <div clss="uk-align-left uk-margin-auto-vertical">
            <label for="name" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Name</label>
            <input type="text" name="name" id="name_form" class="uk-input" placeholder="Name">

            <label for="address" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Address</label>
            <input type="text" name="address" id="address_form" class="uk-input" placeholder="Address">

            <label for="zip_code" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Zip Code</label>
            <input type="text" name="zip_code" id="zip_code_for" class="uk-input" placeholder="Zip Code">'+

            "<div data-controller=\"master-detail\" data-master-detail-victim=\"#cities_form\" data-master-detail-url=\"/cities\">"+
              '<label for="state_form" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Select state</label>'+
              "<select id='state_form' class='uk-input uk-select' data-target='master-detail.master' data-action='master-detail#setVictims'>
                <option value=''>Select an State</option>"+options+
              "</select>
            </div>"+

            '<label for="cities_form" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Select an city</label>'+
            "<select id='cities_form' class='uk-input uk-select'>
              <option value=''>Select an city</option>
            </select>
          </div>"
      })
    else
      Swal.fire({
        sweeConfig...
        title: "Register #{model}",
        cancelButtonText: 'Cancel'
        confirmButtonText: 'Register'
        width: '50%'
        html: '
          <div clss="uk-align-left uk-margin-auto-vertical">
            <label for="name" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Name</label>
            <input type="text" name="name" id="name_form" class="uk-input" placeholder="Name">

            <label for="email_form" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Email</label>
            <input type="email" name="email" id="email_form" class="uk-input" placeholder="Email">

            <label for="address" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Address</label>
            <input type="text" name="address" id="address_form" class="uk-input" placeholder="Address">

            <label for="zip_code" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Zip Code</label>
            <input type="text" name="zip_code" id="zip_code_for" class="uk-input" placeholder="Zip Code">'+

            "<div data-controller=\"master-detail\" data-master-detail-victim=\"#cities_form\" data-master-detail-url=\"/cities\">"+
              '<label for="state_form" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Select state</label>'+
              "<select id='state_form' class='uk-input uk-select' data-target='master-detail.master' data-action='master-detail#setVictims'>
                <option value=''>Select an State</option>"+options+
              "</select>
            </div>"+

            '<label for="cities_form" class="uk-input-label uk-text-bolder blue-text uk-align-left uk-margin-auto-vertical">Select an city</label>'+
            "<select id='cities_form' class='uk-input uk-select'>
              <option value=''>Select an city</option>
            </select>
          </div>"
      })

  fill_producer: (name, address, zip_code, state, cities) ->
    if name == '' || address == '' || zip_code == '' || state == '' || cities == ''
      @alert('Oops...error fill in data', 'error')
      return false
    else
      producer = {
        name: name,
        address_attributes:
          address: address
          zip_code: zip_code
          state_id: parseInt(state)
          city_id: parseInt(cities)
          country_id: 229
      }

  fill_insured: (name, address, zip_code, state, cities, email) ->
    if name == '' || address == '' || zip_code == '' || state == '' || cities == '' || email == ''
      @alert('Oops...error fill in data', 'error')
      return false
    else
      insured = {
        name: name,
        email: email
        address_attributes:
          address: address
          zip_code: zip_code
          state_id: parseInt(state)
          city_id: parseInt(cities)
          country_id: 229
      }

  fill_holder: (name, address, zip_code, state, cities, email) ->
    if name == '' || address == '' || zip_code == '' || state == '' || cities == '' || email == ''
      @alert('Oops...error fill in data', 'error')
      return false
    else
      holder = {
        name: name,
        email: email
        address_attributes:
          address: address
          zip_code: zip_code
          state_id: parseInt(state)
          city_id: parseInt(cities)
          country_id: 229
      }

  alert: (title, type) ->
    if type == 'error'
      Swal.fire({
        icon: 'error'
        title: title
        allowOutsideClick: false
      })
    else if type == 'success'
      Swal.fire({
        icon: 'success'
        title: title
        allowOutsideClick: false
      })

  create: (models, model, id, producer, insured, holder, authenticity_token) ->
    AxiosRequest("#{models}", {authenticity_token, producer, insured, holder}, 'post').then ({data}) =>
      document.getElementById("#{id}").value = data.id
      @nameTarget.textContent = data.name
      @emailTarget.textContent = data.email if model == 'Insured' || model == 'Holder'
      @addressTarget.textContent = data.address
      @cityTarget.textContent = data.city
      @stateTarget.textContent = data.state
      @codeTarget.textContent = data.code

      @alert("Successful #{model} register", 'success')
